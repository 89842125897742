require('./bootstrap');

if (!sessionStorage.getItem('logo_shown')){
    $('.loading-icon').addClass("show");
}
if (sessionStorage.getItem('logo_shown')){
    $('.home-animate').addClass("show");
}


$(document).ready(function(){
    "use strict";


    if (sessionStorage.getItem('logo_shown')){
        $( ".loading-overlay" ).addClass('out');
    }

    if (!sessionStorage.getItem('logo_shown')){
        setTimeout(showpanel, 2000);
        setTimeout(fadeHeadline, 2500);
    }
    sessionStorage.setItem('logo_shown', true);

    function showpanel() {
        $('.loading-overlay').addClass("move-up");
    }
    function fadeHeadline() {
        $('.home-animate').addClass("show");
    }
    function removeIcons() {
        $('.loading-icon').removeClass("show");
    }



    if ($(this).scrollTop() > 70) {
        $('.mainnav').addClass('scrolled');
    } else {
        $('.mainnav').removeClass('scrolled');
    }

    $(window).scroll(function(){
        if ($(this).scrollTop() > 70) {
            $('.mainnav').addClass('scrolled');
        } else {
            $('.mainnav').removeClass('scrolled');
        }
    });

    $(".sliding-link").click(function(e) {
        e.preventDefault();
        var aid = $(this).attr("href");
        $('html,body').animate({scrollTop: ($(aid).offset().top - 70)});

        $('.hamburger').removeClass("is-active");
        $('.nav-links').removeClass("show");
        $('body').removeClass("menu-open");
        $('#mainnav').removeClass("menu-open");
    });

    $(".hamburger").click(function(){
        $(this).toggleClass("is-active");
        $('#nav-links').toggleClass("show");
        $('#mainnav').toggleClass("menu-open");
        $('body').toggleClass("menu-open");
    });
});
